/* eslint-disable no-unused-vars */
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { services } from "../../services/api";
import { NotificacionContext } from "../../context/notificacion_context";
import Swal from "sweetalert2";
import useInconformidadViews from "./useInconformidadViews";

const useInconformidad = () => {
  const steps = [
    "Datos del Paciente",
    "Datos del Representante",
    "Incoformidad",
  ];

  const [newStep, setNewStep] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [loader, setLoader] = useState(false);
  const { showNotification } = useContext(NotificacionContext);
  const regex = /^[0-9\b]*$/;
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [identificacionPaciente, setFilesIdentificacionP] = useState({
    Archivo1: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
  });
  const [identificacionRepresentante, setFilesIdentificacionR] = useState({
    Archivo2: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
  });
  const [credencialDerechoHabliente, setFilesCredencialDerechoHabiente] =
    useState({
      Archivo3: {
        previewicono: "",
        frameicono: true,
        Archivo: null,
      },
    });
  const [recetasRelacionadas, setFilesRecetasRelacionadas] = useState({
    Archivo4: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
  });
  const [estudiosRelacionados, setFilesestudiosRelacionados] = useState({
    Archivo5: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
  });
  const [otroDocumentoR, setFilesOtroDocumentoR] = useState({
    Archivo6: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
  });

  const ArrayFiles1 = [{ key: "Archivo1", name: "Archivo" }];
  const ArrayFiles2 = [{ key: "Archivo2", name: "Archivo" }];
  const ArrayFiles3 = [{ key: "Archivo3", name: "Archivo" }];
  const ArrayFiles4 = [{ key: "Archivo4", name: "Archivo" }];
  const ArrayFiles5 = [{ key: "Archivo5", name: "Archivo" }];
  const ArrayFiles6 = [{ key: "Archivo6", name: "Archivo" }];

  const [inputValues, setInputValues] = useState({
    apellidoMaterno: "",
    apellidoMaternoRepresentante: "",
    apellidoPaterno: "",
    apellidoPaternoRepresentante: "",
    calle: "",
    calleRepresentante: "",
    colonia: "",
    coloniaRepresentante: "",
    conocimientoInconformidad: "0",
    cp: "",
    cpRepresentante: "",
    darSolucion: "",
    donde: "",
    dondeOtros: "",
    edad: "",
    edadRepresentante: "",
    emailAlternoPaciente: "",
    emailAlternoRepresentante: "",
    emailPaciente: "",
    emailRepresentante: "",
    fechaHechos: "",
    fechaNacimientoPaciente: "",
    intPrestadorService: "",
    lugar: "",
    manifiestaOtraInstancia: "0",
    motivoEspecificoInconformidad: "",
    noExterior: "",
    noExteriorRepresentante: "",
    noInterior: "",
    noInteriorRepresentante: "",
    noMalaFe: "0",
    nombrePaciente: "",
    nombreRepresentante: "",
    pertenescoPaciente: "",
    poblacion: "",
    poblacionRepresentante: "",
    sexo: "0",
    sexoRepresentante: "",
    showValidate: false,
    status: 0,
    sucedieron: "",
    telefonoCelular: "",
    telefonoCelularRepresentante: "",
    telefonoFamiliar: "",
    telefonoFijo: "",
    telefonoFijoRepresentante: "",
    errorApellidoMaterno: false,
    errorApellidoPaterno: false,
    errorCalle: false,
    errorColonia: false,
    errorCp: false,
    errorDonde: false,
    errorDondeOtros: false,
    errorEdad: false,
    errorEmailPaciente: false,
    errorFechaHechos: false,
    errorFechaNacimientoPaciente: false,
    errorIntPrestadorService: false,
    errorLugar: false,
    errorMotivoEspecificoInconformidad: false,
    errorNoExterior: false,
    errorNombrePaciente: false,
    errorPoblacion: false,
    errorSucedieron: false,
    errorTelefonoCelular: false,
    errorTelefonoFamiliar: false,
    errorTelefonoCelularCaracteres: false,
    errorTelefonoFamiliarCaracteres: false,
    errorTelefonoFijo: false,
    errorTelefonoFijoCaracteres: false,
    errorTelefonoFijoRepresentanteCaracteres: false,
    errorTelefonoCelularRepresentanteCaracteres: false,
    errorTelefonoFamiliarRepresentanteCaracteres: false,
    errorEmailPacienteEstructura: false,
    errorEmailAlternoPacienteEstructura: false,
    errorEmailRepresentante: false,
    errorEmailAlternoRepresentante: false,
    errorCpPacienteNumeros: false,
    errorCpRepresentanteNumeros: false,
    showDocumentRP: true,
  });

  const navigate = useNavigate();

  const handleBack = () => {
    setInputValues({ ...inputValues, showValidate: false });
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if (activeStep === 3 || activeStep === 6) {
      setNewStep(newStep - 1);
    }
  };

  const handleNext = () => {
    const validate = validateInputs();
    if (validate) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      if (activeStep === 2 || activeStep === 5) {
        setNewStep(newStep + 1);
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    const telefonoFields = [
      "telefonoFijo",
      "telefonoCelular",
      "telefonoFamiliar",
      "telefonoFijoRepresentante",
      "telefonoCelularRepresentante",
    ];
    const edadFields = ["edad", "edadRepresentante"];
    const cpFields = [
      "cp",
      "cpRepresentante",
      "noExterior",
      "noExteriorRepresentante",
    ];
    const names = [
      "nombrePaciente",
      "apellidoPaterno",
      "apellidoMaterno",
      "nombreRepresentante",
      "apellidoPaternoRepresentante",
      "apellidoMaternoRepresentante",
    ];

    if (telefonoFields.includes(name)) {
      if (regex.test(value) && value.length <= 10) {
        setInputValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      }
    } else if (edadFields.includes(name)) {
      if (regex.test(value) && value.length <= 3) {
        setInputValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      }
    } else if (cpFields.includes(name)) {
      if (regex.test(value) && value.length <= 5) {
        setInputValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      }
    } else if (names.includes(name)) {
      if (value.match(/^[a-zA-Z\sáéíóúÁÉÍÓÚñÑüÜ]*$/)) {
        setInputValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      }
    } else {
      setInputValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const sendComplaint = async () => {
    const validate = validateInputs();
    if (validate)  {
      setLoader(true);
      try {
        const formData = new FormData();

        formData.append("apellidoMaterno", inputValues.apellidoMaterno);
        formData.append(
          "apellidoMaternoRepresentante",
          inputValues.apellidoMaternoRepresentante
        );
        formData.append("apellidoPaterno", inputValues.apellidoPaterno);
        formData.append(
          "apellidoPaternoRepresentante",
          inputValues.apellidoPaternoRepresentante
        );
        formData.append("calle", inputValues.calle);
        formData.append("calleRepresentante", inputValues.calleRepresentante);
        formData.append("colonia", inputValues.colonia);
        formData.append(
          "coloniaRepresentante",
          inputValues.coloniaRepresentante
        );
        formData.append(
          "conocimientoInconformidad",
          inputValues.conocimientoInconformidad
        );
        formData.append("cp", inputValues.cp);
        formData.append("cpRepresentante", inputValues.cpRepresentante);
        formData.append("darSolucion", inputValues.darSolucion);
        
        {
          credencialDerechoHabliente.Archivo3.Archivo &&
          formData.append(
            "derechoHabiente", credencialDerechoHabliente.Archivo3.Archivo
          );
        }
        formData.append(
          "donde",
          inputValues.donde === "Otros"
            ? inputValues.dondeOtros
            : inputValues.donde
        );
        formData.append("edad", inputValues.edad);
        formData.append("edadRepresentante", inputValues.edadRepresentante);
        formData.append(
          "emailAlternoPaciente",
          inputValues.emailAlternoPaciente
        );
        formData.append(
          "emailAlternoRepresentante",
          inputValues.emailAlternoRepresentante
        );
        formData.append("emailPaciente", inputValues.emailPaciente);
        formData.append("emailRepresentante", inputValues.emailRepresentante);
        formData.append("fechaHechos", inputValues.fechaHechos);
        formData.append(
          "fechaNacimientoPaciente",
          inputValues.fechaNacimientoPaciente
        );
        formData.append(
          "identificacionPaciente",
          identificacionPaciente.Archivo1.Archivo 
        );

        formData.append("identificacionRepresentante",identificacionRepresentante.Archivo2.Archivo ? identificacionRepresentante.Archivo2.Archivo : "");

        formData.append("intPrestadorService", inputValues.intPrestadorService);
        formData.append("lugar", inputValues.lugar);
        formData.append(
          "manifiestaOtraInstancia",
          inputValues.manifiestaOtraInstancia
        );
        formData.append(
          "motivoEspecificoInconformidad",
          inputValues.motivoEspecificoInconformidad
        );
        formData.append("noExterior", inputValues.noExterior);
        formData.append(
          "noExteriorRepresentante",
          inputValues.noExteriorRepresentante
        );
        formData.append("noInterior", inputValues.noInterior);
        formData.append(
          "noInteriorRepresentante",
          inputValues.noInteriorRepresentante
        );
        formData.append("noMalaFe", inputValues.noMalaFe);
        formData.append("nombrePaciente", inputValues.nombrePaciente);
        formData.append(
          "nombrerRepresentante",
          inputValues.nombreRepresentante
        );
        formData.append("pertenescoPaciente", inputValues.pertenescoPaciente);
        formData.append("poblacion", inputValues.poblacion);
        formData.append(
          "poblacionRepresentante",
          inputValues.poblacionRepresentante
        );
        formData.append("sexo", inputValues.sexo);
        formData.append("sexoRepresentante", inputValues.sexoRepresentante);
        formData.append("status", inputValues.status);
        formData.append("sucedieron", inputValues.sucedieron);
        formData.append("telefonoCelular", inputValues.telefonoCelular);
        formData.append(
          "telefonoCelularRepresentante",
          inputValues.telefonoCelularRepresentante
        );
        formData.append("telefonoFamiliar", inputValues.telefonoFamiliar);
        formData.append("telefonoFijo", inputValues.telefonoFijo);
        formData.append(
          "telefonoFijoRepresentante",
          inputValues.telefonoFijoRepresentante
        );

        {
          recetasRelacionadas.Archivo4.Archivo &&
          formData.append("copiaRecetas", recetasRelacionadas.Archivo4.Archivo);
        }

        {
          estudiosRelacionados.Archivo5.Archivo && 
          formData.append("copiaEstudios", estudiosRelacionados.Archivo5.Archivo);
        }

        { 
          otroDocumentoR.Archivo6.Archivo && 
          formData.append("otroDocumento", otroDocumentoR.Archivo6.Archivo );
        }
        
        const crearQueja = await services("POST", "crearQueja", formData);

        if (crearQueja.status === 200) {
          showNotification("success", crearQueja.data.message, "", null, 4);
          setLoader(false);
          setInputValues({
            apellidoMaterno: "",
            apellidoMaternoRepresentante: "",
            apellidoPaterno: "",
            apellidoPaternoRepresentante: "",
            calle: "",
            calleRepresentante: "",
            colonia: "",
            coloniaRepresentante: "",
            conocimientoInconformidad: "0",
            cp: "",
            cpRepresentante: "",
            darSolucion: "",
            donde: "",
            dondeOtros: "",
            edad: "",
            edadRepresentante: "",
            emailAlternoPaciente: "",
            emailAlternoRepresentante: "",
            emailPaciente: "",
            emailRepresentante: "",
            fechaHechos: "",
            fechaNacimientoPaciente: "",
            intPrestadorService: "",
            lugar: "",
            manifiestaOtraInstancia: "0",
            motivoEspecificoInconformidad: "",
            noExterior: "",
            noExteriorRepresentante: "",
            noInterior: "",
            noInteriorRepresentante: "",
            noMalaFe: "",
            nombrePaciente: "",
            nombreRepresentante: "",
            pertenescoPaciente: "",
            poblacion: "",
            poblacionRepresentante: "",
            sexo: "0",
            sexoRepresentante: "",
            showValidate: false,
            status: 0,
            sucedieron: "",
            telefonoCelular: "",
            telefonoCelularRepresentante: "",
            telefonoFamiliar: "",
            telefonoFijo: "",
            telefonoFijoRepresentante: "",
          });
          setFilesIdentificacionP({
            Archivo1: {
              previewicono: "",
              frameicono: true,
              Archivo: null,
            },
          });
          setFilesIdentificacionR({
            Archivo2: {
              previewicono: "",
              frameicono: true,
              Archivo: null,
            },
          });
          setFilesCredencialDerechoHabiente({
            Archivo3: {
              previewicono: "",
              frameicono: true,
              Archivo: null,
            },
          });
          setFilesRecetasRelacionadas({
            Archivo4: {
              previewicono: "",
              frameicono: true,
              Archivo: null,
            },
          });
          setFilesestudiosRelacionados({
            Archivo5: {
              previewicono: "",
              frameicono: true,
              Archivo: null,
            },
          });
          setFilesOtroDocumentoR({
            Archivo6: {
              previewicono: "",
              frameicono: true,
              Archivo: null,
            },
          });
          navigate("/redireccionando");
        } else if (crearQueja.status === 400) {
          const { data } = crearQueja;
          let htmlErrors = "<ul>";

          for (
            let errorIndex = 0;
            errorIndex < data.error.length;
            errorIndex++
          ) {
            htmlErrors += `<li>${data.error[errorIndex]}</li>`;
          }

          htmlErrors += "</ul>";

          void Swal.fire({
            title: "Ingresa la información correcta",
            html: htmlErrors,
            icon: "warning",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#606770",
          });

          showNotification(
            "warning",
            "Campos Faltantes",
            "Por favor completa todos los campos requeridos y acepta términos y condiciones",
            null,
            4
          );
        }
        setLoader(false);
      } catch (error) {
        showNotification(
          "error",
          "Ups... Algo Sucedió",
          "Error al Agregar la protesta",
          null,
          4
        );
        setLoader(false);
      } finally {
        setLoader(false);
      }
    }
  };

  const validateInputs = () => {
    const requiredFieldsMap = {
      0: [
        "nombrePaciente",
        "apellidoPaterno",
        "apellidoMaterno",
        "edad",
        "fechaNacimientoPaciente",
      ],
      1: ["calle", "noExterior", "colonia", "poblacion", "cp"],
      2: [
        "telefonoFijo",
        "telefonoCelular",
        "emailPaciente",
        "telefonoFamiliar",
      ],
      6: [
        "lugar",
        "donde",
        "fechaHechos",
        "sucedieron",
        "motivoEspecificoInconformidad",
        "darSolucion",
        "intPrestadorService",
      ],
    };

    const cp = ["cp", "cpRepresentante"];
    const emailAlternoPaciente = ["emailAlternoPaciente"];
    const validateShowPDF = [
      "nombreRepresentante",
      "apellidoPaternoRepresentante",
      "apellidoMaternoRepresentante",
    ];
    const validateDondeOtros = ["dondeOtros"];

    const requiredFields = requiredFieldsMap[activeStep];
    if (requiredFields) {
      const errors = {};
      requiredFields.forEach((field) => {
        errors[`error${field.charAt(0).toUpperCase() + field.slice(1)}`] =
          !inputValues[field];
      });

      setInputValues((prevValues) => ({
        ...prevValues,
        ...errors,
      }));

      let cpValid = true;
      let anyError = false;

      cp.forEach((field) => {
        const fieldValue = inputValues[field];
        if (fieldValue) {
          if (fieldValue.length < 5) {
            cpValid = false;
            anyError = true;
            setInputValues((prevValues) => ({
              ...prevValues,
              [`error${
                field.charAt(0).toUpperCase() + field.slice(1)
              }PacienteNumeros`]: true,
            }));
          } else {
            setInputValues((prevValues) => ({
              ...prevValues,
              [`error${
                field.charAt(0).toUpperCase() + field.slice(1)
              }PacienteNumeros`]: false,
            }));
          }
        } else {
          setInputValues((prevValues) => ({
            ...prevValues,
            [`error${
              field.charAt(0).toUpperCase() + field.slice(1)
            }PacienteNumeros`]: false,
          }));
        }
      });

      if (anyError) {
        return false;
      }

      let emailValid = true;

      emailAlternoPaciente.forEach((field) => {
        const fieldValue = inputValues[field];
        if (fieldValue) {
          if (!regexEmail.test(fieldValue)) {
            emailValid = false;
            anyError = true;
            setInputValues((prevValues) => ({
              ...prevValues,
              [`error${
                field.charAt(0).toUpperCase() + field.slice(1)
              }Estructura`]: true,
            }));
          } else {
            setInputValues((prevValues) => ({
              ...prevValues,
              [`error${
                field.charAt(0).toUpperCase() + field.slice(1)
              }Estructura`]: false,
            }));
          }
        } else {
          setInputValues((prevValues) => ({
            ...prevValues,
            [`error${
              field.charAt(0).toUpperCase() + field.slice(1)
            }Estructura`]: false,
          }));
        }
      });

      if (anyError) {
        return false;
      }

      validateShowPDF.forEach((field) => {
        const fieldValue = inputValues[field];
        if (fieldValue !== "") {
          const allFieldsFilled = validateShowPDF.every(
            (field) => inputValues[field] !== ""
          );
          setInputValues((prevValues) => ({
            ...prevValues,
            showDocumentRP: allFieldsFilled,
          }));
        } else {
          setInputValues((prevValues) => ({
            ...prevValues,
            showDocumentRP: false,
          }));
        }
      });

      let phonesValid = true;
      requiredFieldsMap[2].forEach((field) => {
        const fieldValue = inputValues[field];

        if (regexEmail.test(fieldValue)) {
          setInputValues((prevValues) => ({
            ...prevValues,
            errorEmailEstructura: false,
          }));
        }

        if (
          fieldValue &&
          field === "emailPaciente" &&
          !regexEmail.test(fieldValue)
        ) {
          phonesValid = false;
          return setInputValues((prevValues) => ({
            ...prevValues,
            [`error${
              field.charAt(0).toUpperCase() + field.slice(1)
            }Estructura`]: true,
          }));
        } else {
          setInputValues((prevValues) => ({
            ...prevValues,
            [`error${
              field.charAt(0).toUpperCase() + field.slice(1)
            }Estructura`]: false,
          }));
        }
        if (fieldValue && fieldValue.length < 10) {
          phonesValid = false;
          return setInputValues((prevValues) => ({
            ...prevValues,
            [`error${
              field.charAt(0).toUpperCase() + field.slice(1)
            }Caracteres`]: true,
          }));
        } else {
          setInputValues((prevValues) => ({
            ...prevValues,
            [`error${
              field.charAt(0).toUpperCase() + field.slice(1)
            }Caracteres`]: false,
          }));
        }
      });

      if (!phonesValid) {
        return false;
      }

      let validateDondeOtrosValid = true;
      validateDondeOtros.forEach((field) => {
        const fieldValue = inputValues[field];
        if (inputValues.donde === "Otros" && fieldValue === "") {
          validateDondeOtrosValid = false;
          return setInputValues((prevValues) => ({
            ...prevValues,
            [`error${
              field.charAt(0).toUpperCase() + field.slice(1)
            }`]: true,
          }));
        } else {
          setInputValues((prevValues) => ({
            ...prevValues,
            [`error${
              field.charAt(0).toUpperCase() + field.slice(1)
            }`]: false,
          }));
        }
      });

      if (!validateDondeOtrosValid) {
        return false;
      }

      const validate = requiredFields.every(
        (field) => inputValues[field] !== ""
      );
      return validate;
    }

    if (activeStep === 4) {
      let cpValid = true;
      let anyError = false;

      cp.forEach((field) => {
        const fieldValue = inputValues[field];
        if (fieldValue) {
          if (fieldValue.length < 5) {
            cpValid = false;
            anyError = true;
            setInputValues((prevValues) => ({
              ...prevValues,
              [`error${
                field.charAt(0).toUpperCase() + field.slice(1)
              }Numeros`]: true,
            }));
          } else {
            setInputValues((prevValues) => ({
              ...prevValues,
              [`error${
                field.charAt(0).toUpperCase() + field.slice(1)
              }Numeros`]: false,
            }));
          }
        } else {
          setInputValues((prevValues) => ({
            ...prevValues,
            [`error${
              field.charAt(0).toUpperCase() + field.slice(1)
            }Numeros`]: false,
          }));
        }
      });

      if (anyError) {
        return false;
      }

      return true;
    }

    const validateRepresentantInputs = [
      "telefonoFijoRepresentante",
      "telefonoCelularRepresentante",
      "emailRepresentante",
      "emailAlternoRepresentante",
    ];

    if (activeStep === 5) {
      // eslint-disable-next-line no-unused-vars
      let phonesValid = true;
      let anyError = false;

      validateRepresentantInputs.forEach((field) => {
        const fieldValue = inputValues[field];
        if (fieldValue) {
          if (
            (field === "emailRepresentante" ||
              field === "emailAlternoRepresentante") &&
            !regexEmail.test(fieldValue)
          ) {
            phonesValid = false;
            anyError = true;
            setInputValues((prevValues) => ({
              ...prevValues,
              [`error${field.charAt(0).toUpperCase() + field.slice(1)}`]: true,
            }));
          } else if (
            (field === "telefonoFijoRepresentante" ||
              field === "telefonoCelularRepresentante") &&
            fieldValue.length < 10
          ) {
            phonesValid = false;
            anyError = true;
            setInputValues((prevValues) => ({
              ...prevValues,
              [`error${
                field.charAt(0).toUpperCase() + field.slice(1)
              }Caracteres`]: true,
            }));
          } else {
            setInputValues((prevValues) => ({
              ...prevValues,
              [`error${field.charAt(0).toUpperCase() + field.slice(1)}`]: false,
              [`error${
                field.charAt(0).toUpperCase() + field.slice(1)
              }Caracteres`]: false,
            }));
          }
        } else {
          setInputValues((prevValues) => ({
            ...prevValues,
            [`error${field.charAt(0).toUpperCase() + field.slice(1)}`]: false,
            [`error${
              field.charAt(0).toUpperCase() + field.slice(1)
            }Caracteres`]: false,
          }));
        }
      });

      if (anyError) {
        return false;
      }

      return true;
    }

    if (activeStep === 7 && inputValues.showDocumentRP) {
      let anyError = false;
      const fieldValue = identificacionRepresentante.Archivo2.Archivo;
      if (!fieldValue) {
        anyError = true;
      } 

      if (anyError) {
        Swal.fire({
          title: "Error",
          html: "Campos de Identificación Requeridos",
          icon: "error",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#606770",
        });
        return false;
      }

      return true;
    }
    
    return true;
  };

  return {
    steps,
    activeStep,
    newStep,
    loader,
    identificacionPaciente,
    identificacionRepresentante,
    credencialDerechoHabliente,
    recetasRelacionadas,
    estudiosRelacionados,
    otroDocumentoR,
    setFilesIdentificacionP,
    setFilesIdentificacionR,
    setFilesCredencialDerechoHabiente,
    setFilesRecetasRelacionadas,
    setFilesestudiosRelacionados,
    setFilesOtroDocumentoR,
    ArrayFiles1,
    ArrayFiles2,
    ArrayFiles3,
    ArrayFiles4,
    ArrayFiles5,
    ArrayFiles6,
    inputValues,
    handleBack,
    handleNext,
    handleChange,
    sendComplaint,
  };
};

export default useInconformidad;
