import React, { useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { GlobalContext } from "../context/global_context";
import {
  maxLengthLimitInput,
  onlyNumberPhone,
  onlyLetter,
  onlyNumberLinea,
} from "../utils/inputsRules";

export default function FormularioRepresentante({ quejaSeleccionada }) {
  const { globalState, setGlobalState } = useContext(GlobalContext);

  const handleCurpRepresentante = (e) => {
    setGlobalState({ ...globalState, curpRepresentante: e.target.value });
  };

  const handleNombreRepresentante = (e) => {
    setGlobalState({ ...globalState, nombreRepresentante: e.target.value });
  };

  const handleaPaternoRepresentante = (e) => {
    setGlobalState({ ...globalState, aPaternoRepresentante: e.target.value });
  };

  const handleaMaternoRepresentante = (e) => {
    setGlobalState({ ...globalState, aMaternoRepresentante: e.target.value });
  };

  const handleEdadRepresentante = (e) => {
    setGlobalState({ ...globalState, edadRepresentante: e.target.value });
  };

  const handleFechaNacimientoRepresentante = (e) => {
    setGlobalState({
      ...globalState,
      fechaNacimientoRepresentante: e.target.value,
    });
  };

  const handleTelefonoFijoRepresentante = (e) => {
    setGlobalState({
      ...globalState,
      telefonoFijoRepresentante: e.target.value,
    });
  };

  const handleTelefonoMovilRepresentante = (e) => {
    setGlobalState({
      ...globalState,
      telefonoMovilRepresentante: e.target.value,
    });
  };

  const handleTelefonoOficinaRepresentante = (e) => {
    setGlobalState({
      ...globalState,
      telefonoOficinaRepresentante: e.target.value,
    });
  };

  // const handleTelefonoRecadosRepresentante = (e) =>{
  //     setGlobalState({...globalState, telefonoRecadosRepresentante: e.target.value })
  // }

  const handleEmailRepresentante = (e) => {
    setGlobalState({ ...globalState, emailRepresentante: e.target.value });
  };

  return (
    <div className="FormularioRepresentante">
      {/*<div className=" filaCurp row ms-5">
        <div className="col">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p
                  className="text-morado"
                  style={{ borderBottom: "1px solid gray" }}
                >
                  CURP:{" "}
                  {quejaSeleccionada.curpRepresentante !== "undefined"
                    ? quejaSeleccionada.curpRepresentante
                    : ""}
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="curp"
                  label="Curp"
                  type="text"
                  variant="standard"
                  // error={globalState.curpRepresentanteError}
                  value={globalState.curpRepresentante.toUpperCase()}
                  helperText="Ingresa la Curp"
                  inputProps={{
                    style: { textTransform: "uppercase", color: "#4A001F" },
                  }}
                  onChange={handleCurpRepresentante}
                  onKeyPress={(event) => {
                    maxLengthLimitInput(event, 18, "curp");
                  }}
                />
              )}
            </Grid>
          </Box>
        </div>
      </div>*/}

      <div className="row">
        <div className="col d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Nombre(s):</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.nombrerRepresentante !== "undefined"
                      ? quejaSeleccionada.nombrerRepresentante
                      : ""}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="nombre"
                  label="Nombre(s)"
                  type="text"
                  // error={globalState.nombreRepresentanteError}
                  value={globalState.nombrerRepresentante}
                  variant="standard"
                  helperText="Ingresa el Nombre"
                  onChange={handleNombreRepresentante}
                  onKeyPress={(event) => {
                    onlyLetter(event, 55);
                  }}
                />
              )}
            </Grid>
          </Box>
        </div>

        <div className="col  d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Apellido Paterno:</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.apellidoPaternoRepresentante !==
                    "undefined"
                      ? quejaSeleccionada.apellidoPaternoRepresentante
                      : ""}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="apellidoPaterno"
                  label="Apellido Paterno"
                  type="text"
                  // error={globalState.aPaternoRepresentanteError}
                  value={globalState.aPaternoRepresentante}
                  variant="standard"
                  helperText="Ingresa el primer Apellido"
                  onChange={handleaPaternoRepresentante}
                  onKeyPress={(event) => {
                    onlyLetter(event, 55);
                  }}
                />
              )}
            </Grid>
          </Box>
        </div>

        <div className="col  d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Apellido Materno:</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.apellidoMaternoRepresentante !==
                    "undefined"
                      ? quejaSeleccionada.apellidoMaternoRepresentante
                      : ""}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="apellidoMaterno"
                  label="Apellido Materno"
                  // error={globalState.aMaternoRepresentanteError}
                  value={globalState.aMaternoRepresentante}
                  type="text"
                  variant="standard"
                  helperText="Ingresa el segundo Apellido"
                  onChange={handleaMaternoRepresentante}
                  onKeyPress={(event) => {
                    onlyLetter(event, 55);
                  }}
                />
              )}
            </Grid>
          </Box>
        </div>
      </div>

      <div className="row">
        <div className="col d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              <Grid item lg={4} xs={6}>
                {quejaSeleccionada ? (
                  <p style={{ borderBottom: "1px solid gray" }}>
                    <span className="text-morado">Género:</span>{" "}
                    <span style={{ color: "black" }}>
                      {quejaSeleccionada.sexoRepresentante === 0
                        ? "Masculino"
                        : quejaSeleccionada.sexoRepresentante === 1
                        ? "Femenino"
                        : quejaSeleccionada.sexoRepresentante === 2
                        ? "LGBTQ+"
                        : quejaSeleccionada.sexoRepresentante === 3
                        ? "Sin especificar"
                        : ""}
                    </span>
                  </p>
                ) : (
                  <>
                    <label className="text-morado">Sexo</label>
                    <div className="d-flex flex-column flex-lg-row  aling-content-center mt-4">
                      <div className="rb1 ">
                        <input
                          id="radio10"
                          type="radio"
                          checked={globalState.selected5 === "radio10"}
                          onChange={() => {
                            setGlobalState({
                              ...globalState,
                              selected5: "radio10",
                            });
                          }}
                        />
                        <label className="me-5" for="radio10">
                          Femenino
                        </label>
                      </div>

                      <div className="rb1">
                        <input
                          id="radio11"
                          type="radio"
                          checked={globalState.selected5 === "radio11"}
                          onChange={() => {
                            setGlobalState({
                              ...globalState,
                              selected5: "radio11",
                            });
                          }}
                        />{" "}
                        <label for="radio11">Masculino</label>
                      </div>
                    </div>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </div>

        <div className="col  d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Edad:</span>{" "}
                  <span style={{ color: "black" }}>
                    {quejaSeleccionada.edadRepresentante !== undefined
                      ? quejaSeleccionada.edadRepresentante
                      : ""}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="edad"
                  label="Edad"
                  value={globalState.edadRepresentante}
                  type="text"
                  variant="standard"
                  helperText="Ingresa la Edad"
                  onChange={handleEdadRepresentante}
                />
              )}
            </Grid>
          </Box>
        </div>

        <div className="col  d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Correo Electrónico:</span>{" "}
                  <span style={{ color: "black" }}>
                    {quejaSeleccionada.emailRepresentante !== "undefined"
                      ? quejaSeleccionada.emailRepresentante
                      : ""}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="email"
                  label="Correo Electrónico"
                  type="text"
                  variant="standard"
                  error={globalState.emailRepresentanteError}
                  value={globalState.emailRepresentante}
                  helperText="Ingresa el correo Electrónico"
                  onChange={handleEmailRepresentante}
                  onKeyPress={(event) => {
                    maxLengthLimitInput(event, 55, "email");
                  }}
                />
              )}
            </Grid>
          </Box>
        </div>

        {/*<div className="col  d-flex justify-content-center">
                    <Box
                        component="form"
                        sx={{
                            "& > :not(style)": {m: 1, width: "30ch"},
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid>
                            {quejaSeleccionada ? (
                                <p
                                    className="text-morado"
                                    style={{borderBottom: "1px solid gray"}}
                                >
                                    Fecha de Nacimiento:{" "}
                                    {quejaSeleccionada.fechaNacimientoRepresentante !==
                                    "undefined"
                                        ? quejaSeleccionada.fechaNacimientoRepresentante
                                        : ""}
                                </p>
                            ) : (
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    id="fechaNacimiento"
                                    label="Fecha de Nacimiento"
                                    type="date"
                                    value={globalState.fechaNacimientoRepresentante}
                                    variant="standard"
                                    helperText="Ingresa la fecha de Nacimiento"
                                    onChange={handleFechaNacimientoRepresentante}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        </Grid>
                    </Box>
                </div>*/}
      </div>

      <div className="row">
        <div className="col d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Teléfono Fijo:</span>{" "}
                  <span style={{ color: "black" }}>
                    {quejaSeleccionada.telefonoFijoRepresentante !== undefined
                      ? quejaSeleccionada.telefonoFijoRepresentante
                      : ""}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="telefonoFijo"
                  label="Teléfono Fijo"
                  type="text"
                  value={globalState.telefonoFijoRepresentante}
                  // error={globalState.telefonoFijoRepresentanteError}
                  variant="standard"
                  helperText="Ingresa el teléfono fijo con lada incluida"
                  onChange={handleTelefonoFijoRepresentante}
                  onKeyPress={onlyNumberPhone}
                />
              )}
            </Grid>
          </Box>
        </div>

        <div className="col  d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Teléfono Móvil:</span>{" "}
                  <span style={{ color: "black" }}>
                    {quejaSeleccionada.telefonoCelularRepresentante !==
                    undefined
                      ? quejaSeleccionada.telefonoCelularRepresentante
                      : ""}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="telefonoMovil"
                  label="Teléfono Móvil"
                  value={globalState.telefonoMovilRepresentante}
                  type="text"
                  variant="standard"
                  helperText="Ingresa el número de celular"
                  onChange={handleTelefonoMovilRepresentante}
                  onKeyPress={onlyNumberPhone}
                />
              )}
            </Grid>
          </Box>
        </div>

        <div className="col  d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Población:</span>{" "}
                  <span style={{ color: "black" }}>
                    {quejaSeleccionada.poblacionRepresentante !== undefined
                      ? quejaSeleccionada.poblacionRepresentante
                      : ""}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="telefonoOficina"
                  label="Teléfono de Oficina"
                  type="text"
                  value={globalState.telefonoOficinaRepresentante}
                  variant="standard"
                  helperText="Ingresa el teléfono de oficina con Lada incluida"
                  onChange={handleTelefonoOficinaRepresentante}
                  onKeyPress={onlyNumberPhone}
                />
              )}
            </Grid>
          </Box>
        </div>
      </div>
    </div>
  );
}
