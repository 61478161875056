import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

export default function FormularioUnidadMedica({ quejaSeleccionada }) {

  /* const dictionaryPlace = {
    0: "IMSS",
    1: "isste",
    2: "IMSS BIENESTAR",
  }; */

  const obtenerValoresFecha = (fecha) => {
    const date = new Date(fecha);
    return {
      dia: date.getDate() + 1,
      mes: date.getMonth(),
      año: date.getFullYear(),
      hora: date.getHours(),
      minutos: date.getMinutes(),
      segundos: date.getSeconds(),
    };
  };

  const meses = {
    0: "Enero",
    1: "Febrero",
    2: "Marzo",
    3: "Abril",
    4: "Mayo",
    5: "Junio",
    6: "Julio",
    7: "Agosto",
    8: "Septiembre",
    9: "Octubre",
    10: "Noviembre",
    11: "Diciembre",
  };

  const fecha = obtenerValoresFecha(quejaSeleccionada.fechaHechos);
  
  return (
    <div className="FormularioUnidadMedica">
      <div className="row">
        <div className="col-11 nombreUnidadMedica ">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { mt: 3 },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid item xs={6}>
              {quejaSeleccionada && (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Los hechos ocurrieron en:</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.donde}
                  </span>
                </p>
              )}
            </Grid>
          </Box>
        </div>
      </div>

      <div className="row">
        <div className="col-11 nombreUnidadMedica ">
          <Box
            component="form"
            sx={{
              "& > :not(style)": {  },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada && (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Lugar y fecha:</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.lugar +
                      ", " +
                      fecha.dia +
                      " de " +
                      meses[fecha.mes] +
                      " de " +
                      fecha.año}
                  </span>
                </p>
              )}
            </Grid>
          </Box>
        </div>
      </div>

      <div className="row">
        <div className="col-11 nombreUnidadMedica ">
          <Box
            component="form"
            sx={{
              "& > :not(style)": {  },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada && (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">
                    Narrativa de hechos (como sucedieron):
                  </span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.sucedieron}
                  </span>
                </p>
              )}
            </Grid>
          </Box>
        </div>
      </div>

      <div className="row">
        <div className="col-11 nombreUnidadMedica ">
          <Box
            component="form"
            sx={{
              "& > :not(style)": {  },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada && (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">
                    Mencione el motivo específico de la inconformidad:
                  </span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.motivoEspecificoInconformidad}
                  </span>
                </p>
              )}
            </Grid>
          </Box>
        </div>
      </div>

      <div className="row">
        <div className="col-11 nombreUnidadMedica ">
          <Box
            component="form"
            sx={{
              "& > :not(style)": {  },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada && (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">
                    Especifique que solicita para dar solución a la
                    inconformidad:
                  </span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.darSolucion}
                  </span>
                </p>
              )}
            </Grid>
          </Box>
        </div>
      </div>

      <div className="row">
        <div className="col-11 nombreUnidadMedica ">
          <Box
            component="form"
            sx={{
              "& > :not(style)": {  },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada && (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">
                    Manifieste si el prestador de servicios médicos Y/O
                    Institución, tiene conocimiento de la inconformidad:
                  </span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.conocimientoInconformidad === 1
                      ? "Si"
                      : quejaSeleccionada.conocimientoInconformidad === 0
                      ? "No"
                      : ""}
                  </span>
                </p>
              )}
            </Grid>
          </Box>
        </div>
      </div>

      <div className="row">
        <div className="col-11 nombreUnidadMedica ">
          <Box
            component="form"
            sx={{
              "& > :not(style)": {  },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada && (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">
                    Manifestar si ha presentado la inconformidad ante otra
                    instancia para resolverla:
                  </span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.manifiestaOtraInstancia === 1
                      ? "Si"
                      : quejaSeleccionada.manifiestaOtraInstancia === 0
                      ? "No"
                      : ""}
                  </span>
                </p>
              )}
            </Grid>
          </Box>
        </div>
      </div>

      <div className="row">
        <div className="col-11 nombreUnidadMedica ">
          <Box
            component="form"
            sx={{
              "& > :not(style)": {  },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada && (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">
                    Manifestar que no existe mala fe en la presentación de esta
                    queja y lo asentado en ella es verdad:
                  </span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.noMalaFe === 1
                      ? "Si"
                      :  "No"
                    }
                  </span>
                </p>
              )}
            </Grid>
          </Box>
        </div>
      </div>

      <div className="row">
        <div className="col-11 nombreUnidadMedica ">
          <Box
            component="form"
            sx={{
              "& > :not(style)": {  },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada && (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">
                    Anotar los nombres completo y domicilio (con código postal y
                    numero telefónico del los prestadores de servicios de salud
                    que intervinieron en la atención en salud reclamada):
                  </span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.intPrestadorService}
                  </span>
                </p>
              )}
            </Grid>
          </Box>
        </div>
      </div>

      <div className="row">
        <div className="col-11 nombreUnidadMedica ">
          <Box
            component="form"
            sx={{
              "& > :not(style)": {  },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada && (
                <p
                  className="text-morado"
                  style={{ borderBottom: "1px solid gray" }}
                >
                  Si por algun motivo durante el tiempo que dure la atención de
                  la informidad, cambia de domicilio Y/O teléfono, deberá de
                  hacerlo del conocimiento de la COESAMM, en un tiempo no mayor
                  a cinco días hábiles.
                </p>
              )}
            </Grid>
          </Box>
        </div>
      </div>
    </div>
  );
}
