import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import CardImagenInput from "../../components/ImagenInputCard";
import useWindowResolution from "../useWindowResolution";
import ModalTextBox from "../../components/ModalTextBox";
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import Swal from "sweetalert2";

const useInconformidadViews = (
  activeStep,
  inputValues,
  identificacionPaciente,
  identificacionRepresentante,
  credencialDerechoHabliente,
  recetasRelacionadas,
  estudiosRelacionados,
  otroDocumentoR,
  setFilesIdentificacionP,
  setFilesIdentificacionR,
  setFilesCredencialDerechoHabiente,
  setFilesRecetasRelacionadas,
  setFilesestudiosRelacionados,
  setFilesOtroDocumentoR,
  ArrayFiles1,
  ArrayFiles2,
  ArrayFiles3,
  ArrayFiles4,
  ArrayFiles5,
  ArrayFiles6,
  handleChange
) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const { width } = useWindowResolution();

  const arrayPDF = [
    {
      name: "Identificación Oficial del Paciente",
      files: identificacionPaciente,
      setFiles: setFilesIdentificacionP,
      NameFiles: ArrayFiles1,
      show: true,
    },
    {
      name: "Identificación Oficial de quien presenta la Inconformidad, si no es el paciente",
      files: identificacionRepresentante,
      setFiles: setFilesIdentificacionR,
      NameFiles: ArrayFiles2,
      show: inputValues.showDocumentRP,
    },
    {
      name: "Si es derechohabiente del IMSS, ISSSTE, IMSS BIENESTAR, o cualquier otra institución que preste servicios de salud como derechohabiente, anexar la credencial de la institución cartilla de citas y/o cualquier otro documento que acredite ser derechohabiente",
      files: credencialDerechoHabliente,
      setFiles: setFilesCredencialDerechoHabiente,
      NameFiles: ArrayFiles3,
      show: true,
    },
    {
      name: "Copias de las Recetas relacionadas con el acto reclamado",
      files: recetasRelacionadas,
      setFiles: setFilesRecetasRelacionadas,
      NameFiles: ArrayFiles4,
      show: true,
    },
    {
      name: "Copia de estudios de laboratorio clínico y/o gabinete relacionados con el hecho",
      files: estudiosRelacionados,
      setFiles: setFilesestudiosRelacionados,
      NameFiles: ArrayFiles5,
      show: true,
    },
    {
      name: "Cualquier otro documento que considere este vinculado con el hecho reclamado",
      files: otroDocumentoR,
      setFiles: setFilesOtroDocumentoR,
      NameFiles: ArrayFiles6,
      show: true,
    },
  ];

  const condition = width <= 1743;
  const limitCharacter = width <= 1251 ? 32 : 62;

  const limitText = (text, limit, i) => {
    if (text.length > limit) {
      return (
        <>
          {text.substring(0, limit)}...
          <span
            style={{ cursor: "pointer", color: "#6a0f49" }}
            onClick={() => {
              setSelectedText(text);
              setShowModal(true);
            }}
          >
            más
          </span>
        </>
      );
    }
    return text;
  };

  const heightTextBox = (index) => {
    if (
      !inputValues.showDocumentRP &&
      !condition &&
      index !== 0 &&
      index !== 2
    ) {
      return "10vh";
    } else if (index !== 0 && index !== 2 && index !== 3 && condition) {
      return "10vh";
    } else if (condition) {
      return "10vh";
    } else if (
      index !== 0 &&
      index !== 2 &&
      index !== 3 &&
      !condition &&
      inputValues.showDocumentRP
    ) {
      return "10vh";
    } else if (index === 0 && inputValues.showDocumentRP) {
      return "10vh";
    } else {
      return "18vh";
    }
  };

  const textoRepresentante = "En caso de no contar con representante, favor de presionar el botón 'Siguiente'";

  const zeroStep = () => {
    return (
      <>
        <div className="container_form ">
          <div className="d-flex align-items-start justify-content-start">
            <Typography className="text-morado" variant="h6" gutterBottom>
              Datos del Paciente{" "}
            </Typography>
          </div>
          <div className="container_inputs ">
            <TextField
              className="inputs_form inputs_form me-2 my-2"
              fullWidth
              type="text"
              required
              name="nombrePaciente"
              label="Nombre(s) del Paciente"
              variant="filled"
              value={inputValues.nombrePaciente}
              onChange={handleChange}
              error={inputValues.errorNombrePaciente}
              helperText={
                inputValues.errorNombrePaciente
                  ? "Este campo es obligatorio"
                  : ""
              }
            />
            <TextField
              className="inputs_form inputs_form me-2 my-2"
              fullWidth
              type="text"
              required
              label="Apellido Paterno"
              name="apellidoPaterno"
              variant="filled"
              value={inputValues.apellidoPaterno}
              onChange={handleChange}
              error={inputValues.errorApellidoPaterno}
              helperText={
                inputValues.errorApellidoPaterno
                  ? "Este campo es obligatorio"
                  : ""
              }
            />
          </div>
          <div className="container_inputs">
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              type="text"
              required
              label="Apellido Materno"
              name="apellidoMaterno"
              variant="filled"
              value={inputValues.apellidoMaterno}
              onChange={handleChange}
              error={inputValues.errorApellidoMaterno}
              helperText={
                inputValues.errorApellidoMaterno
                  ? "Este campo es obligatorio"
                  : ""
              }
            />
            <FormControl className="inputs_radio">
              <FormLabel>Género</FormLabel>
              <RadioGroup
                required
                name="sexo"
                value={inputValues.sexo}
                onChange={handleChange}
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="Masculino"
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Femenino"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="LGBTQ+"
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="Prefiero no decirlo"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="container_inputs ">
            <TextField
              className="inputs_form inputs_form me-2 my-2"
              fullWidth
              type="text"
              required
              label="Edad"
              name="edad"
              variant="filled"
              value={inputValues.edad}
              onChange={handleChange}
              error={inputValues.errorEdad}
              helperText={
                inputValues.errorEdad ? "Este campo es obligatorio" : ""
              }
            />
            <TextField
              className="inputs_form inputs_form me-2 my-2"
              fullWidth
              type="date"
              label="Fecha Nacimiento *"
              name="fechaNacimientoPaciente"
              variant="filled"
              value={inputValues.fechaNacimientoPaciente}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={inputValues.errorFechaNacimientoPaciente}
              helperText={
                inputValues.errorFechaNacimientoPaciente
                  ? "Este campo es obligatorio"
                  : ""
              }
            />
          </div>
        </div>
      </>
    );
  };

  const firstStep = () => {
    return (
      <>
        <div className="container_form ">
          <div className="d-flex align-items-start justify-content-start">
            <Typography className="text-morado" variant="h6" gutterBottom>
              Domicilio del Paciente{" "}
            </Typography>
          </div>
          <div className="container_inputs">
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              type="text"
              required
              label="Calle"
              name="calle"
              variant="filled"
              value={inputValues.calle}
              onChange={handleChange}
              error={inputValues.errorCalle}
              helperText={
                inputValues.errorCalle ? "Este campo es obligatorio" : ""
              }
            />
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              type="text"
              required
              label="Número Exterior"
              name="noExterior"
              variant="filled"
              value={inputValues.noExterior}
              onChange={handleChange}
              error={inputValues.errorNoExterior}
              helperText={
                inputValues.errorNoExterior ? "Este campo es obligatorio" : ""
              }
            />
          </div>
          <div className="container_inputs ">
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              type="text"
              label="Número Interior"
              name="noInterior"
              variant="filled"
              value={inputValues.noInterior}
              onChange={handleChange}
            />
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              type="text"
              required
              label="Colonia"
              name="colonia"
              variant="filled"
              value={inputValues.colonia}
              onChange={handleChange}
              error={inputValues.errorColonia}
              helperText={
                inputValues.errorColonia ? "Este campo es obligatorio" : ""
              }
            />
          </div>
          <div className="container_inputs">
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              type="text"
              required
              label="Población"
              name="poblacion"
              variant="filled"
              value={inputValues.poblacion}
              onChange={handleChange}
              error={inputValues.errorPoblacion}
              helperText={
                inputValues.errorPoblacion ? "Este campo es obligatorio" : ""
              }
            />
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              type="text"
              required
              label="Código Postal"
              name="cp"
              variant="filled"
              value={inputValues.cp}
              onChange={handleChange}
              error={inputValues.errorCp || inputValues.errorCpPacienteNumeros}
              helperText={
                inputValues.errorCp
                  ? "Este campo es obligatorio"
                  : inputValues.errorCpPacienteNumeros
                  ? "El código postal debe tener 5 dígitos"
                  : ""
              }
            />
          </div>
        </div>
      </>
    );
  };

  const secondStep = () => {
    return (
      <>
        <div className="container_form ">
          <div className="d-flex align-items-start justify-content-start">
            <Typography className="text-morado" variant="h6" gutterBottom>
              Datos del Paciente{" "}
            </Typography>
          </div>
          <div className="container_inputs ">
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              required
              type="text"
              label="Teléfono Fijo"
              name="telefonoFijo"
              variant="filled"
              value={inputValues.telefonoFijo}
              onChange={handleChange}
              error={
                inputValues.errorTelefonoFijo ||
                inputValues.errorTelefonoFijoCaracteres
              }
              helperText={
                inputValues.errorTelefonoFijo
                  ? "Este campo es obligatorio"
                  : inputValues.errorTelefonoFijoCaracteres
                  ? "El número debe tener 10 dígitos"
                  : ""
              }
            />
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              required
              label="Teléfono Celular "
              name="telefonoCelular"
              variant="filled"
              type="text"
              value={inputValues.telefonoCelular}
              onChange={handleChange}
              error={
                inputValues.errorTelefonoCelular ||
                inputValues.errorTelefonoCelularCaracteres
              }
              helperText={
                inputValues.errorTelefonoCelular
                  ? "Este campo es obligatorio"
                  : inputValues.errorTelefonoCelularCaracteres
                  ? "El número debe tener 10 dígitos"
                  : ""
              }
            />
          </div>
          <div className="container_inputs ">
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              required
              type="text"
              label="Teléfono de un familiar a donde se pueda comunicar la COESAMM"
              name="telefonoFamiliar"
              variant="filled"
              value={inputValues.telefonoFamiliar}
              onChange={handleChange}
              error={
                inputValues.errorTelefonoFamiliar ||
                inputValues.errorTelefonoFamiliarCaracteres
              }
              helperText={
                inputValues.errorTelefonoFamiliar
                  ? "Este campo es obligatorio"
                  : inputValues.errorTelefonoFamiliarCaracteres
                  ? "El número debe tener 10 dígitos"
                  : ""
              }
            />
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              required
              type="email"
              label="Correo Electrónico"
              name="emailPaciente"
              variant="filled"
              value={inputValues.emailPaciente}
              onChange={handleChange}
              error={
                inputValues.errorEmailPaciente ||
                inputValues.errorEmailPacienteEstructura
              }
              helperText={
                inputValues.errorEmailPaciente
                  ? "Este campo es obligatorio"
                  : inputValues.errorEmailPacienteEstructura
                  ? "El correo no tiene la estructura correcta"
                  : ""
              }
            />
          </div>
          <div className="container_inputs ">
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              label="Correo Electrónico Alterno"
              name="emailAlternoPaciente"
              variant="filled"
              value={inputValues.emailAlternoPaciente}
              onChange={handleChange}
              error={inputValues.errorEmailAlternoPacienteEstructura}
              helperText={
                inputValues.errorEmailAlternoPacienteEstructura
                  ? "El correo no tiene la estructura correcta"
                  : ""
              }
            />
          </div>
        </div>
      </>
    );
  };

  const thirdStep = () => {
    return (
      <>
        <div className="container_form ">
          <div className="d-flex align-items-start justify-content-start flex-column">
            <Typography className="text-morado" variant="h6" gutterBottom>
              Datos del Representante{" "}
            </Typography>
          </div>
          <div className="container_inputs ">
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              label="Nombre(s) del Representante"
              name="nombreRepresentante"
              variant="filled"
              value={inputValues.nombreRepresentante}
              onChange={handleChange}
            />
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              label="Apellido Paterno"
              name="apellidoPaternoRepresentante"
              variant="filled"
              value={inputValues.apellidoPaternoRepresentante}
              onChange={handleChange}
            />
          </div>
          <div className="container_inputs ">
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              label="Apellido Materno"
              name="apellidoMaternoRepresentante"
              variant="filled"
              value={inputValues.apellidoMaternoRepresentante}
              onChange={handleChange}
            />
            <FormControl className="inputs_radio">
              <FormLabel>Género</FormLabel>
              <RadioGroup
                aria-label="sexo"
                name="sexoRepresentante"
                value={inputValues.sexoRepresentante}
                onChange={handleChange}
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="Masculino"
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Femenino"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="LGBTQ+"
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="Prefiero no decirlo"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="container_inputs ">
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              label="Edad"
              name="edadRepresentante"
              variant="filled"
              type="text"
              value={inputValues.edadRepresentante}
              onChange={handleChange}
            />
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              label="Parentesco y/o relación que tiene con el paciente"
              name="pertenescoPaciente"
              variant="filled"
              value={inputValues.pertenescoPaciente}
              onChange={handleChange}
            />
          </div>
          <p
            style={{
              fontSize: "12px",
              color: "#6A0F49",
              textAlign: "justify",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              backgroundColor: "#f9f9f9",
              marginTop: "5%",
              textTransform: "uppercase",
            }}
          >
            {textoRepresentante}
          </p>
        </div>
      </>
    );
  };

  const fourthStep = () => {
    return (
      <>
        <div className="container_form ">
          <div className="d-flex align-items-start justify-content-start">
            <Typography className="text-morado" variant="h6" gutterBottom>
              Domicilio del Representante{" "}
            </Typography>
          </div>
          <div className="container_inputs ">
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              label="Calle"
              name="calleRepresentante"
              variant="filled"
              value={inputValues.calleRepresentante}
              onChange={handleChange}
            />
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              label="Número Exterior"
              name="noExteriorRepresentante"
              variant="filled"
              type="text"
              value={inputValues.noExteriorRepresentante}
              onChange={handleChange}
            />
          </div>
          <div className="container_inputs ">
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              label="Número Interior"
              name="noInteriorRepresentante"
              variant="filled"
              type="text"
              value={inputValues.noInteriorRepresentante}
              onChange={handleChange}
            />
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              label="Colonia"
              name="coloniaRepresentante"
              variant="filled"
              value={inputValues.coloniaRepresentante}
              onChange={handleChange}
            />
          </div>
          <div className="container_inputs ">
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              label="Población"
              name="poblacionRepresentante"
              variant="filled"
              value={inputValues.poblacionRepresentante}
              onChange={handleChange}
            />
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              label="Código Postal"
              name="cpRepresentante"
              variant="filled"
              type="text"
              value={inputValues.cpRepresentante}
              onChange={handleChange}
              error={inputValues.errorCpRepresentanteNumeros}
              helperText={
                inputValues.errorCpRepresentanteNumeros
                  ? "El código postal debe tener 5 dígitos"
                  : ""
              }
            />
          </div>
          <p
            style={{
              fontSize: "12px",
              color: "#6A0F49",
              textAlign: "justify",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              backgroundColor: "#f9f9f9",
              marginTop: "5%",
              textTransform: "uppercase",
            }}
          >
           {textoRepresentante}
          </p>
        </div>
      </>
    );
  };

  const fifthStep = () => {
    return (
      <>
        <div className="container_form ">
          <div className="d-flex align-items-start justify-content-start">
            <Typography className="text-morado" variant="h6" gutterBottom>
              Datos del Representante{" "}
            </Typography>
          </div>
          <div className="container_inputs ">
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              label="Teléfono Fijo"
              name="telefonoFijoRepresentante"
              variant="filled"
              type="text"
              value={inputValues.telefonoFijoRepresentante}
              onChange={handleChange}
              error={inputValues.errorTelefonoFijoRepresentanteCaracteres}
              helperText={
                inputValues.errorTelefonoFijoRepresentanteCaracteres
                  ? "El número debe tener 10 dígitos"
                  : ""
              }
            />
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              label="Teléfono Celular "
              name="telefonoCelularRepresentante"
              variant="filled"
              type="text"
              value={inputValues.telefonoCelularRepresentante}
              onChange={handleChange}
              error={inputValues.errorTelefonoCelularRepresentanteCaracteres}
              helperText={
                inputValues.errorTelefonoCelularRepresentanteCaracteres
                  ? "El número debe tener 10 dígitos"
                  : ""
              }
            />
          </div>
          <div className="container_inputs ">
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              label="Correo Electrónico"
              name="emailRepresentante"
              variant="filled"
              value={inputValues.emailRepresentante}
              onChange={handleChange}
              error={inputValues.errorEmailRepresentante}
              helperText={
                inputValues.errorEmailRepresentante
                  ? "La estructura del correo no es válida"
                  : ""
              }
            />
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              label="Correo Electrónico Alterno"
              name="emailAlternoRepresentante"
              variant="filled"
              value={inputValues.emailAlternoRepresentante}
              onChange={handleChange}
              error={inputValues.errorEmailAlternoRepresentante}
              helperText={
                inputValues.errorEmailAlternoRepresentante
                  ? "La estructura del correo no es válida"
                  : ""
              }
            />
          </div>
          <p
            style={{
              fontSize: "12px",
              color: "#6A0F49",
              textAlign: "justify",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              backgroundColor: "#f9f9f9",
              marginTop: "5%",
              textTransform: "uppercase",
            }}
          >
            {textoRepresentante}
          </p>
        </div>
      </>
    );
  };

  const sixthStep = () => {
    return (
      <>
        <div className="container_form ">
          <div className="d-flex align-items-start justify-content-start">
            <Typography className="text-morado" variant="h6" gutterBottom>
              Información de la Inconformidad{" "}
            </Typography>
          </div>
          <div className="container_inputs ">
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              required
              label="Lugar"
              name="lugar"
              variant="filled"
              value={inputValues.lugar}
              onChange={handleChange}
              error={inputValues.errorLugar}
              helperText={
                inputValues.errorLugar ? "Este campo es obligatorio" : ""
              }
            />
            <div className="container_inputDonde ">
              <FormControl className="inputs_radio">
                <FormLabel>Donde</FormLabel>
                <RadioGroup
                  required
                  name="donde"
                  variant="filled"
                  value={inputValues.donde}
                  onChange={handleChange}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <FormControlLabel
                    value="IMSS"
                    control={<Radio />}
                    label="IMSS"
                  />
                  <FormControlLabel
                    value="ISSSTE"
                    control={<Radio />}
                    label="ISSSTE"
                  />
                  <FormControlLabel
                    value="IMSS Bienestar"
                    control={<Radio />}
                    label="IMSS Bienestar"
                  />
                  <FormControlLabel
                    value="Secretaría de Salud"
                    control={<Radio />}
                    label="Secretaría de Salud" 
                  />
                  <FormControlLabel
                    value="Servicios Médicos"
                    control={<Radio />}
                    label="Servicios Médicos "
                  />
                  <FormControlLabel
                    value="Hospitalarios Privados"
                    control={<Radio />}
                    label="Hospitalarios Privados"
                  />
                  <FormControlLabel
                    value="Otros"
                    control={<Radio />}
                    label="Otros"
                  />
                </RadioGroup>
                {(inputValues.errorDonde ) && (
                  <Typography style={{ color: "#c40404", fontSize: "12px", marginLeft:"4%"}}>
                    Este campo es obligatorio
                  </Typography>
                )}
              </FormControl>
              {inputValues.donde === "Otros" && (
                <TextField
                  className="inputs_formDonde me-2 my-2"
                  fullWidth
                  required
                  label="Donde"
                  name="dondeOtros"
                  variant="filled"
                  value={inputValues.dondeOtros}
                  onChange={handleChange}
                  error={inputValues.errorDondeOtros}
                  helperText={
                    inputValues.errorDondeOtros && "Este campo es obligatorio"
                  }
                />
              )}
            </div>
          </div>
          <div className="container_inputs ">
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              required
              type="date"
              date_format="Y-m-d"
              label="Fecha de los Hechos"
              name="fechaHechos"
              variant="filled"
              value={inputValues.fechaHechos}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={inputValues.errorFechaHechos}
              helperText={
                inputValues.errorFechaHechos ? "Este campo es obligatorio" : ""
              }
            />
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              required
              label="¿Cómo sucedieron los Hechos? "
              name="sucedieron"
              variant="filled"
              value={inputValues.sucedieron}
              onChange={handleChange}
              error={inputValues.errorSucedieron}
              helperText={
                inputValues.errorSucedieron ? "Este campo es obligatorio" : ""
              }
            />
          </div>
          <div className="container_inputs ">
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              required
              label="MENCIONE EL MOTIVO ESPECIFICO DE LA INCONFORMIDAD "
              name="motivoEspecificoInconformidad"
              variant="filled"
              value={inputValues.motivoEspecificoInconformidad}
              onChange={handleChange}
              error={inputValues.errorMotivoEspecificoInconformidad}
              helperText={
                inputValues.errorMotivoEspecificoInconformidad
                  ? "Este campo es obligatorio"
                  : ""
              }
            />
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              required
              label="ESPECIFIQUE QUÉ SOLICITA PARA DAR SOLUCIÓN A LA INCONFORMIDAD"
              name="darSolucion"
              variant="filled"
              value={inputValues.darSolucion}
              onChange={handleChange}
              style={{ marginBottom: "5%" }}
              error={inputValues.errorDarSolucion}
              helperText={
                inputValues.errorDarSolucion ? "Este campo es obligatorio" : ""
              }
            />
          </div>
          <div className="container_inputs ">
            <FormControl className="inputs_form">
              <FormLabel>
                MANIFIESTE SI EL PRESTADOR DE SERVICIOS MÉDICOS Y/O INSTITUCIÓN,
                TIENE CONOCIMIENTO DE LA INCONFORMIDAD.
              </FormLabel>
              <RadioGroup
                className="inputs_form me-2 my-2"
                name="conocimientoInconformidad"
                value={inputValues.conocimientoInconformidad}
                onChange={handleChange}
                style={{
                  marginLeft: "5%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FormControlLabel value="0" control={<Radio />} label="Si" />
                <FormControlLabel value="1" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <FormControl className="inputs_form">
              <FormLabel>
                MANIFESTAR SI HA PRESENTADO LA INCONFORMIDAD ANTE OTRA INSTANCIA
                PARA RESOLVERLA. SI REQUIERE ESPECIFICAR MÁS, PUEDE HACERLO EN EL CAMPO DE "MÁS DOCUMENTOS".
              </FormLabel>
              <RadioGroup
                className="inputs_form me-2 my-2"
                name="manifiestaOtraInstancia"
                value={inputValues.manifiestaOtraInstancia}
                onChange={handleChange}
                style={{
                  marginLeft: "5%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FormControlLabel value="0" control={<Radio />} label="Si" />
                <FormControlLabel value="1" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="container_inputs ">
            <FormControl className="inputs_form">
              <FormLabel>
                MANIFESTAR QUE NO EXISTE MALA FE EN LA PRESENTACIÓN DE LA QUEJA
                Y LO ASENTADO EN ELLA ES VERDAD. 
              </FormLabel>
              <RadioGroup
                className="inputs_form me-2 my-2"
                required
                name="noMalaFe"
                variant="filled"
                value={inputValues.noMalaFe}
                onChange={handleChange}
                style={{
                  marginLeft: "5%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FormControlLabel value="0" control={<Radio />} label="Si" />
                <FormControlLabel value="1" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <TextField
              className="inputs_form me-2 my-2"
              fullWidth
              label="ANOTAR LOS NOMBRES COMPLETOS Y DOMICILIO (CON CÓDIGO POSTAL Y NUMERO TELEFÓNICO DEL LOS PRESTADORES DE SERVICIOS DE SALUD QUE INTERVINIERON EN LA ATENCIÓN EN SALUD RECLAMADA"
              name="intPrestadorService"
              variant="filled"
              value={inputValues.intPrestadorService}
              onChange={handleChange}
              error={inputValues.errorIntPrestadorService}
              helperText={
                inputValues.errorIntPrestadorService
                  ? "Este campo es obligatorio"
                  : ""
              }
            />
          </div>
          <div className="container_inputs "></div>
          <p
            style={{
              fontSize: "12px",
              color: "#6A0F49",
              textAlign: "justify",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              backgroundColor: "#f9f9f9",
              marginTop: "5%",
            }}
          >
            SI POR ALGÚN MOTIVO DURANTE EL TIEMPO QUE DURE LA ATENCIÓN DE LA
            INFORMIDAD, CAMBIA DE DOMICILIO Y/O TELÉFONO, DEBERÁ DE HACERLO DEL
            CONOCIMIENTO DE LA COESAMM, EN UN TIEMPO NO MAYOR A CINCO DÍAS
            HÁBILES.
          </p>
        </div>
      </>
    );
  };

  const seventhStep = () => {
    return (
      <>
        <div className="container_form ">
          <div className="d-flex align-items-start justify-content-start">
            <Typography className="text-morado" variant="h6" gutterBottom>
              Documentación{" "}
            </Typography>
          </div>
          <div className="container-pdf">
            {arrayPDF.map(
              (item, index) =>
                item.show && (
                  <div key={index}>
                    <div
                      className="pdf-item"
                      style={{
                        height: heightTextBox(index),
                      }}
                    >
                      <p>
                        {condition
                          ? limitText(item.name, limitCharacter, index)
                          : item.name}
                      </p>
                    </div>

                    <CardImagenInput
                      Files={item.files}
                      setFiles={item.setFiles}
                      NameFiles={item.NameFiles}
                      Description="Desliza tu archivo formato PDF"
                      FormatFiles=".pdf"
                      MensajeCarga="Cargar Archivo"
                      onlyOne={false}
                    />
                  </div>
                )
            )}
          </div>
        </div>

        <ModalTextBox
          show={showModal}
          handleClose={() => setShowModal(false)}
          body={selectedText}
        />
      </>
    );
  };

  const stepsDictionary = [
    zeroStep,
    firstStep,
    secondStep,
    thirdStep,
    fourthStep,
    fifthStep,
    sixthStep,
    seventhStep,
  ];

  const getCardContent = () => {
    return stepsDictionary[activeStep] ? stepsDictionary[activeStep]() : null;
  };

  return {
    getCardContent,
  };
};

export default useInconformidadViews;
