import React, { useEffect, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { GlobalContext } from "../context/global_context";
import { NotificacionContext } from "../context/notificacion_context";
import {
  maxLengthLimitInput,
  onlyNumberPhone,
  onlyLetter,
  onlyNumberLinea,
  onlyNumber,
} from "../utils/inputsRules";
import { styled } from "@mui/material/styles";
import { BorderBottom } from "@mui/icons-material";

const CustomTextField = styled(TextField)({
  "& .MuiInputLabel-root": {
    textTransform: "uppercase",
    color: "#ffc3d0",
  },
});

function FormularioPaciente({ quejaSeleccionada }) {
  const { globalState, setGlobalState } = useContext(GlobalContext);
  const { showNotification } = useContext(NotificacionContext);
  const [errors, setErrors] = useState({});

  const handleCurp = (e) => {
    setGlobalState({ ...globalState, curp: e.target.value });
  };

  const handleNombre = (e) => {
    setGlobalState({ ...globalState, nombre: e.target.value });
  };

  const handleApellidoPaterno = (e) => {
    setGlobalState({ ...globalState, apellidoPaterno: e.target.value });
  };

  const handleApellidoMAterno = (e) => {
    setGlobalState({ ...globalState, apellidoMaterno: e.target.value });
  };

  const handleEdad = (e) => {
    setGlobalState({ ...globalState, edad: e.target.value });
  };

  const handleFechaNacimiento = (e) => {
    setGlobalState({ ...globalState, fechaNacimiento: e.target.value });
  };

  const handleTelefonoFijo = (e) => {
    setGlobalState({ ...globalState, telefonoFijo: e.target.value });
  };

  const handleTelefonoMovil = (e) => {
    setGlobalState({ ...globalState, telefonoMovil: e.target.value });
  };

  const handleTelefonoOficina = (e) => {
    setGlobalState({ ...globalState, telefonoOficina: e.target.value });
  };

  // const handleTelefonoRecados = (e) =>{
  //     setGlobalState({...globalState, telefonoRecados:e.target.value})
  // }

  const handleEmail = (e) => {
    setGlobalState({ ...globalState, email: e.target.value });
  };

  return (
    <div className="FormularioPaciente">
      {/*<div className="filaCurp row ms-5">
        <div className="col">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p
                  className="text-morado"
                  style={{ borderBottom: "1px solid gray" }}
                >
                  CURP: {quejaSeleccionada.curp}
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="curp"
                  label="*Curp"
                  type="text"
                  error={globalState.curpError}
                  name="curp"
                  value={globalState.curp.toUpperCase()}
                  variant="standard"
                  helperText="Ingresa tu Curp"
                  // inputProps={{ style: { textTransform: "uppercase"}}}
                  onChange={handleCurp}
                  onKeyPress={(e) => {
                    maxLengthLimitInput(e, 18, "curp");
                  }}
                />
              )}
            </Grid>
          </Box>
        </div>
      </div>*/}

      <div className="row">
        <div className="col d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Nombre(s):</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.nombrePaciente}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="nombre"
                  label="*Nombre(s)"
                  type="text"
                  error={globalState.nombreError}
                  value={globalState.nombre}
                  variant="standard"
                  helperText="Ingresa tu Nombre"
                  onChange={handleNombre}
                  onKeyPress={(event) => {
                    onlyLetter(event, 55);
                  }}
                />
              )}
            </Grid>
          </Box>
        </div>

        <div className="col  d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Apellido Paterno:</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.apellidoPaterno}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="apellidoPaterno"
                  label="*Apellido Paterno"
                  type="text"
                  error={globalState.apellidoPaternoError}
                  value={globalState.apellidoPaterno}
                  variant="standard"
                  helperText="Ingresa tu primer Apellido"
                  onChange={handleApellidoPaterno}
                  onKeyPress={(event) => {
                    onlyLetter(event, 55);
                  }}
                />
              )}
            </Grid>
          </Box>
        </div>

        <div className="col  d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Apellido Materno:</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.apellidoMaterno}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="apellidoMaterno"
                  label="*Apellido Materno"
                  type="text"
                  error={globalState.apellidoMaternoError}
                  value={globalState.apellidoMaterno}
                  variant="standard"
                  helperText="Ingresa tu segundo Apellido"
                  onChange={handleApellidoMAterno}
                  onKeyPress={(event) => {
                    onlyLetter(event, 55);
                  }}
                />
              )}
            </Grid>
          </Box>
        </div>
      </div>

      <div className="row">
        <div className="col d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid item lg={4} xs={6}>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Género:</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.sexo === 0
                      ? "Masculino"
                      : quejaSeleccionada.sexo === 1
                      ? "Femenino"
                      : quejaSeleccionada.sexo === 2
                      ? "LGBTQ+"
                      : quejaSeleccionada.sexo === 3
                      ? "Sin especificar"
                      : ""}
                  </span>
                </p>
              ) : (
                <>
                  <label className="text-morado">Sexo</label>
                  <div className="d-flex flex-column flex-lg-row  aling-content-center mt-4">
                    <div className="rb1 ">
                      <input
                        name="sexo"
                        id="radio8"
                        type="radio"
                        checked={globalState.selected4 === "radio8"}
                        onChange={() => {
                          setGlobalState({
                            ...globalState,
                            selected4: "radio8",
                          });
                        }}
                      />
                      <label className="me-5" for="radio8">
                        Femenino
                      </label>
                    </div>

                    <div className="rb1">
                      <input
                        name="sexo"
                        id="radio9"
                        type="radio"
                        checked={globalState.selected4 === "radio9"}
                        onChange={() => {
                          setGlobalState({
                            ...globalState,
                            selected4: "radio9",
                          });
                        }}
                      />{" "}
                      <label for="radio9">Masculino</label>
                    </div>
                  </div>
                </>
              )}
            </Grid>
          </Box>
        </div>

        <div className="col  d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Edad:</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.edad}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="edad"
                  label="Edad"
                  type="text"
                  value={globalState.edad}
                  variant="standard"
                  helperText="Ingresa tu Edad"
                  onChange={handleEdad}
                />
              )}
            </Grid>
          </Box>
        </div>

        <div className="col  d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Fecha de Nacimiento:</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.fechaNacimientoPaciente}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="fechaNacimiento"
                  label="*Fecha de Nacimiento"
                  type="date"
                  error={globalState.fechaNacimientoError}
                  value={globalState.fechaNacimiento}
                  variant="standard"
                  helperText="Ingresa tu fecha de Nacimiento"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleFechaNacimiento}
                />
              )}
            </Grid>
          </Box>
        </div>
      </div>

      <div className="row">
        <div className="col d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Teléfono Fijo:</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.telefonoFijo}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="telefonoFijo"
                  name="telefonoFijo"
                  error={globalState.telefonoFijoError}
                  label="Teléfono Fijo"
                  value={globalState.telefonoFijo}
                  type="text"
                  variant="standard"
                  helperText="Ingresa tu teléfono fijo con lada incluida"
                  onChange={handleTelefonoFijo}
                  onKeyPress={onlyNumberPhone}
                />
              )}
            </Grid>
          </Box>
        </div>

        <div className="col  d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Teléfono Móvil:</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.telefonoCelular}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="telefonoMovil"
                  label="*Teléfono Móvil"
                  type="text"
                  error={globalState.telefonoMovilError}
                  value={globalState.telefonoMovil}
                  variant="standard"
                  helperText="Ingresa tu número de celular"
                  onChange={handleTelefonoMovil}
                  onKeyPress={onlyNumberPhone}
                />
              )}
            </Grid>
          </Box>
        </div>

        <div className="col  d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Correo Electrónico:</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.emailPaciente}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="email"
                  error={globalState.emailError}
                  label="*Correo Electrónico"
                  value={globalState.email}
                  type="text"
                  variant="standard"
                  helperText="Ingresa tu correo Electrónico"
                  onChange={handleEmail}
                  onKeyPress={(event) => {
                    maxLengthLimitInput(event, 55, "email");
                  }}
                />
              )}
            </Grid>
            {/*<Grid>
              {quejaSeleccionada ? (
                <p
                  className="text-morado"
                  style={{ borderBottom: "1px solid gray" }}
                >
                  Teléfono de Oficina: {quejaSeleccionada.telefonoOficina}
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="telefonoOficina"
                  label="Teléfono de Oficina"
                  type="text"
                  value={globalState.telefonoOficina}
                  variant="standard"
                  helperText="Ingresa tu teléfono de oficina con Lada incluida"
                  onChange={handleTelefonoOficina}
                  onKeyPress={onlyNumberPhone}
                />
              )}
            </Grid>*/}
          </Box>
        </div>
      </div>

      <div className="row">
        {/* <div className='col d-flex justify-content-center'>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '30ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid>
                            <TextField fullWidth margin="normal"

                                id="telefonoRecados"
                                label="Teléfono para recados"
                                value={globalState.telefonoRecados}
                                type="text"
                                variant="standard"
                                helperText='Ingresa tu teléfono para Recados'
                                onChange={handleTelefonoRecados}

                            />

                        </Grid>


                    </Box>

                </div> */}

        {/* <div className="col mt-2 d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Correo Electrónico:</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.emailPaciente}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="email"
                  error={globalState.emailError}
                  label="*Correo Electrónico"
                  value={globalState.email}
                  type="text"
                  variant="standard"
                  helperText="Ingresa tu correo Electrónico"
                  onChange={handleEmail}
                  onKeyPress={(event) => {
                    maxLengthLimitInput(event, 55, "email");
                  }}
                />
              )}
            </Grid>
          </Box>
        </div> */}

        {/* <div className="col mt-3 d-flex justify-content-center">
          <Grid item xs={4}>
            {quejaSeleccionada ? (
              <p style={{ borderBottom: "1px solid gray" }}>
                <span className="text-morado">
                  ¿Cuenta con Promovente o Representante?:
                </span>
                <span style={{ color: "black" }}>
                  {" "}
                  {quejaSeleccionada.promovente === 1
                    ? "Si"
                    : quejaSeleccionada.promovente === 0
                    ? "No"
                    : ""}
                </span>
              </p>
            ) : (
              <>
                <label className="mb-3 text-morado">
                  ¿Cuenta con Promovente o Representante?
                </label>
                <div className="rb1 d-flex justify-content-center ">
                  <input
                    className="me-2"
                    checked={globalState.selected === "radio1"}
                    onChange={() => {
                      setGlobalState({ ...globalState, selected: "radio1" });
                    }}
                    id="radio1"
                    type="radio"
                  />
                  <label className="me-5" for="radio1">
                    Si
                  </label>
                  <input
                    className="me-2"
                    checked={globalState.selected === "radio2"}
                    onChange={() => {
                      setGlobalState({ ...globalState, selected: "radio2" });
                    }}
                    id="radio2"
                    type="radio"
                  />{" "}
                  <label for="radio2">No</label>
                </div>
              </>
            )}
          </Grid>
        </div> */}
      </div>
    </div>
  );
}

export default FormularioPaciente;
